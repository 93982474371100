import React, { useState } from 'react';
import { useSortBy } from 'react-instantsearch-core';

import { generateKey } from '../../../../utils/keys';

import { ReactComponent as CaretSVG } from '../../../../images/caret_down.svg';

import './customSortBy.scss';

export type CustomSortByProps = {
  items: {
    value: string;
    label: string;
    isRefined: boolean;
  }[];
};

export const CustomSortBy : React.FC<CustomSortByProps> = (props) => {
  const { items } = props;
  const { refine, currentRefinement } = useSortBy({ items: items.map(i => ({ value: i.value, label: i.label})) });

  const getCurrentOptionLabel = (): string =>
    items.find((item) => item.value === currentRefinement)?.label ?? '';

  const [isOpened, setIsOpened] = useState(false);
  const [currentOption, setCurrentOption] = useState<string>(
    getCurrentOptionLabel
  );

  return (
    <div className="sort-refinement-panel">
      <div
        className={`refinement-type ${isOpened ? 'opened-panel' : ''}`}
        onClick={() => setIsOpened(!isOpened)}
      >
        {currentOption}
        <div className="caret-container">
          <CaretSVG
            fill="#142A1E"
            style={isOpened ? { transform: 'rotate(180deg)' } : {}}
          />
        </div>
      </div>
      <div className={`filter-list ${isOpened ? 'opened-list' : ''}`}>
        {items &&
          items.map(item => {
            return (
              <div
                key={generateKey(item.label)}
                className={`filter-item ${item.value === currentRefinement ? 'refined' : ''}`}
                onClick={() => {
                  refine(item.value);
                  setCurrentOption(item.label);
                  setIsOpened(false);
                }}
              >
                {item.label}
              </div>
            );
          })}
      </div>
    </div>
  );
};