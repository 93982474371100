import React, { useContext } from 'react';

import GlobalContext from '../../../context/global-context';
import creeStamp from '../../../images/certified_checkmark.svg';

import './creeCertified.scss';

const CreeCertified: React.FC<{
  full?: boolean;
}> = ({ full = true }) => {
  const { i18n } = useContext(GlobalContext);

  return (
    <div className="cree-certified-wrapper">
      <div className="icon-container">
        <img src={creeStamp} alt="cree-stamp" />
      </div>
      {full && (
        <span>{i18n?.t('global.cree_certified') ?? 'Cree certified'}</span>
      )}
    </div>
  );
};

export default CreeCertified;
