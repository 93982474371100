import React from 'react';
import { useToggleRefinement } from 'react-instantsearch-core';

import './toggleRefinement.scss';

type CustomToggleRefinement = {
  attribute: string;
  label: string;
};

export const CustomToggleRefinement : React.FC<CustomToggleRefinement> = (props) => {
  const { attribute, label } = props;
  const { createURL, refine, value } = useToggleRefinement({ attribute });

  return (
    <div className="toggle-refinement">
      <input
        type="checkbox"
        id={label}
        checked={value.isRefined}
        onChange={(e) => {
          e.preventDefault();
          refine(value);
          createURL();
        }}
      />
      <label htmlFor={label}>{label}</label>
    </div>
  );
};