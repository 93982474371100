import React, { Dispatch, SetStateAction, useContext } from 'react';
import { ClearRefinements } from 'react-instantsearch';

import GlobalContext from '../../../context/global-context';
import { ReactComponent as CloseSVG } from '../../../images/close_big.svg';
import { ReactComponent as OptionsSVG } from '../../../images/options.svg';
import CustomButton, {
  buttonOptions
} from '../../global/customButton/customButton';
import { CustomRefinementList } from '../refinementList/refinementList';
import './refinementDrawer.scss';
import { CustomToggleRefinement } from '../toggleRefinement/toggleRefinement';

type RefinementDrawerProps = {
  toggle: Dispatch<SetStateAction<boolean>>;
  isOpened: boolean;
  filterCount: number;
};

export const RefinementDrawer : React.FC<RefinementDrawerProps> = (props) => {
  const { toggle, isOpened, filterCount } = props;
  const { i18n } = useContext(GlobalContext);

  const renderMobileMenu = () => {
    return (
      <div
        className={`mobile-panel`}
        style={isOpened ? { height: '100%' } : { height: '0%' }}
      >
        <div className="panel-content">
          <div className="title">
            <span className="option-svg">
              <OptionsSVG />
            </span>
            {i18n?.t('filters.mobile.title')}
            <span className="close-svg" onClick={() => toggle(!isOpened)}>
              <CloseSVG />
            </span>
          </div>
          <div className="filters">
            <>
              <CustomToggleRefinement
                attribute="cree_certified"
                label={i18n?.t('refinements.cree_certified') ??
                  'Show certified business only'
                }
              />
              <CustomRefinementList
                title={i18n?.t('refinements.industry') ?? 'Industry'}
                attribute="naics_categories"
              />
              <CustomRefinementList
                title={
                  i18n?.t('refinements.based_in_community') ??
                  'Based in community'
                }
                attribute="based_in_community"
              />
              <CustomRefinementList
                title={
                  i18n?.t('refinements.can_operate_in_communities') ??
                  'Operates in community'
                }
                attribute="can_operate_in_communities"
              />
              <CustomRefinementList
                title={
                  i18n?.t('refinements.seasons_of_operation') ??
                  'Operates in season'
                }
                attribute="seasons_of_operation"
              />
              <CustomRefinementList
                title={
                  i18n?.t('refinements.legal_structure') ?? 'Legal structure'
                }
                attribute="legal_structure"
              />
            </>
          </div>
          <div className="buttons">
            <div className="filter-btn">
              <CustomButton
                style={buttonOptions.filled}
                classSuffix="--small"
                onClick={() => toggle(!isOpened)}
              >
                {i18n?.t('filters.mobile.search')}
              </CustomButton>
            </div>
            {filterCount != 0 ? (
              <div className="clear-btn">
                <ClearRefinements
                  translations={{
                    resetButtonText: i18n?.t('business_registry.clear'),
                  }}
                />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  };
  
  return (
    <>{renderMobileMenu()}</>
  );
};