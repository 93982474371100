import React, { useContext } from 'react';

import GlobalContext from '../../../context/global-context';
import { CustomRefinementList } from '../refinementList/refinementList';
import './refinementPanel.scss';
import { CustomToggleRefinement } from '../toggleRefinement/toggleRefinement';

export const RefinementPanel : React.FC = () => {
  const { i18n } = useContext(GlobalContext);

  return (
    <div className="desktop-panel">
      <CustomToggleRefinement
        attribute="cree_certified"
        label={
          i18n?.t('refinements.cree_certified') ??
          'Show certified business only'
        }
      />
      <CustomRefinementList
        title={i18n?.t('refinements.industry') ??
          'Industry'}
        attribute="naics_categories"
        limit={100}
      />
      <CustomRefinementList
        title={i18n?.t('refinements.based_in_community') ??
          'Based in community'
        }
        attribute="based_in_community"
        limit={50}
      />
      <CustomRefinementList
        title={i18n?.t('refinements.can_operate_in_communities') ??
          'Operates in community'}
        attribute="can_operate_in_communities"
        limit={100}
      />
      <CustomRefinementList
        title={i18n?.t('refinements.seasons_of_operation') ??
          'Operates in season'}
        attribute="seasons_of_operation"
      />
      <CustomRefinementList
        title={i18n?.t('refinements.legal_structure') ??
          'Legal structure'}
        attribute="legal_structure"
        limit={50}
      />
    </div>
  );
};