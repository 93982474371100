import React, { useContext } from 'react';
import { useHits } from 'react-instantsearch';

import GlobalContext from '../../../context/global-context';
import { useLocaleFromRouter } from '../../../hooks/useLocaleFromRouter';
import { ReactComponent as PhoneSVG } from '../../../images/phone.svg';
import { ReactComponent as PinSVG } from '../../../images/pin.svg';
import { ReactComponent as WebsiteSVG } from '../../../images/website.svg';
import { BusinessHit } from '../../../types/businessRegistry';
import {
  buildPathForDetailsPage,
  phoneLink
} from '../../../utils/formatStrings';
import { getUploadUrl } from '../../../utils/general';
import { LocaleType } from '../../../utils/i18n';
import { generateKey } from '../../../utils/keys';
import CreeCertified from '../../global/creeCertified/creeCertified';

import './businessHits.scss';

export const BusinessHits : React.FC = () => {
  const { items } = useHits<BusinessHit>();
  const { i18n } = useContext(GlobalContext);
  const currentLocale: LocaleType = useLocaleFromRouter();

  return (
    <div>
      {items.map((business, index) => {
        const {
          address,
          city,
          creeCertified,
          phone,
          postalCode,
          province,
          publicFacingDescription,
          publicFacingLegalBusinessName,
          slug,
          thumbnail,
          website
        } = business;

        return (
          <div
            key={generateKey(`business_${index}`)}
            className="business-hit-wrapper"
          >
            <a
              className="business-hit"
              href={buildPathForDetailsPage(
                slug,
                'business_page',
                currentLocale,
                i18n
              )}
              target={'_blank'}
            >
              <div className="business-thumbnail">
                {thumbnail?.data && (
                  <figure
                    style={{
                      backgroundImage: `url(${getUploadUrl(
                        thumbnail.data?.attributes.url
                      )})`
                    }}
                  />
                )}
              </div>

              <div className="business-description">
                <h2>{publicFacingLegalBusinessName}</h2>
                <p>{publicFacingDescription}</p>
              </div>
              <div className="business-contact-info">
                <ul>
                  {creeCertified && (
                    <li className="no-padding-left">
                      <CreeCertified />
                    </li>
                  )}
                  {website && (
                    <li>
                      <WebsiteSVG className="icon" />
                      <a
                        href={website &&
                          !/^https?:\/\//i.test(website)
                            ? `http://${website}`
                            : website
                        }
                        target={'_blank'}
                      >
                        {i18n?.t('see_website')}
                      </a>
                    </li>
                  )}
                  {phone && (
                    <li>
                      <PhoneSVG className="icon" />
                      <a href={phoneLink(phone)}>{phone}</a>
                    </li>
                  )}
                  {address && city && province && postalCode && (
                    <li>
                      <PinSVG className="icon" />
                      <div>{address}</div>
                      <div>{`${city}, ${province}, ${postalCode}`}</div>
                    </li>
                  )}
                </ul>
              </div>
            </a>
          </div>
        )
      })}
    </div>
  );
};