import { navigate } from 'gatsby-link';
import React, { useContext } from 'react';

import GlobalContext from '../../../context/global-context';
import { useLocaleFromRouter } from '../../../hooks/useLocaleFromRouter';
import { LocaleType } from '../../../utils/i18n';
import CustomButton, {
  buttonOptions,
} from '../../global/customButton/customButton';
import './register.scss';
import { redirectToDashboardBusinessCreate } from '../../../utils/user';

const Register: React.FC = () => {
  const currentLocale: LocaleType = useLocaleFromRouter();
  const { i18n, isLogged } = useContext(GlobalContext);
  const pathParts = [currentLocale, i18n?.t('register.slug')];

  function onRegisterBusinessClicked() {
    isLogged
      ? redirectToDashboardBusinessCreate(currentLocale, i18n)
      : navigate(`/${pathParts.join('/')}`);
  }

  return (
    <div className="search-register">
      <h4 className="title">{i18n?.t('business_registry.register.title')}</h4>
      <p className="body">{i18n?.t('business_registry.register.body')}</p>
      <CustomButton
        style={buttonOptions.filled_light}
        onClick={onRegisterBusinessClicked}
        className="register-business-cta"
      >
        {i18n?.t('business_registry.register.cta')}
      </CustomButton>
    </div>
  );
};

export default Register;
